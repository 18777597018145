import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};
const routes = [
  {
    path: "/read",
    // meta: { title: "用户协议" },
    component: () => import("../views/read/index.vue"),
  },
  {
    path: "/readmember",
    // meta: { title: "会员协议" },
    component: () => import("../views/read/memberead.vue"),
  },
  {
    path: "/login",
    // meta: { title: "登录" },
    component: () => import("../views/login/index.vue"),
  },
  // 编辑页面
  {
    path: "/edit",
    name: "newsIndex",
    component: () => import("../views/edit/index.vue"),
    redirect: "/edit",
    children: [
      // 新闻列表页面
      {
        path: "/edit",
        component: () => import("../views/edit/newsList.vue"),
      },
      // 新闻修改
      {
        path: "/newsListEdit",
        component: () => import("../views/edit/newsListEdit.vue"),
      },

      // 专家列表
      {
        path: "/personList",
        component: () => import("../views/edit/personList.vue"),
      },
      // 专家个人信息修改
      {
        path: "/expertPersonEdit",
        component: () => import("../views/edit/expertPersonEdit.vue"),
      },
      // 访谈列表
      {
        path: "/expertList",
        component: () => import("../views/edit/expertList.vue"),
      },
      // 访谈详情
      {
        path: "/expertInfo",
        component: () => import("../views/edit/expertInfo.vue"),
      },
    ],
  },
  {
    path: "/",
    // meta: { title: "首页" },
    redirect: "/",
    name: "Home",
    component: Home,
    children: [
      // 首页
      {
        path: "/",
        // component: () => import("../views/frist/index.vue"),
        component: () => import("../views/trading/index.vue"),
      },
      // 交易大厅
      // {
      //   path: "/trading",
      //   name: "Trading",
      //   component: () => import("../views/trading/index.vue"),
      // },
      // 用户中心
      {
        path: "/user",
        name: "User",
        component: () => import("../views/user/index.vue"),
      },
      // 用户中心-挂牌详情
      {
        path: "/usergpdetail",
        name: "Usergpdetail",
        component: () => import("../views/user/gpdetail.vue"),
      },
      // 用户中心-摘牌详情
      {
        path: "/userzpdetail",
        name: "Userzpdetail",
        component: () => import("../views/user/zpdetail.vue"),
      },
      // 用户中心-订单列表-订单详情（同摘牌详情）
      // {
      //   path: "/userdddetail",
      //   name: "Userdddetail",
      //   component: () => import("../views/user/dddetail.vue"),
      // },
      // 我要买
      {
        path: "/buy",
        name: "Buy",
        component: () => import("../views/buy/index.vue"),
      },
      // 双碳培训
      {
        path: "/peixun",
        name: "Peixun",
        component: () => import("../views/peixun/index.vue"),
      },

      // 交易大厅列表
      {
        path: "/buylist",
        name: "Buylist",
        component: () => import("../views/buy/list.vue"),
      },
      {
        path: "/buydetail",
        name: "Buydetail",
        component: () => import("../views/buy/buyDetail.vue"),
      },
      // 交易行情详情页
      {
        path: "/tradetail",
        name: "Tradetail",
        component: () => import("../views/tradetail/index.vue"),
      },
      // 会员中心
      {
        path: "/members",
        name: "Members",
        component: () => import("../views/members/index.vue"),
      },
      {
        path: "/tradetail",
        name: "Tradetail",
        component: () => import("../views/tradetail/index.vue"),
      },
      // 成交情况-碳市场分析
      {
        path: "/market",
        name: "Market",
        component: () => import("../views/market/index.vue"),
      },
      // 月度报告
      {
        path: "/monthreport",
        name: "Monthreport",
        component: () => import("../views/monthreport/index.vue"),
      },
      // 产品分析
      {
        path: "/productvip",
        name: "Productvip",
        component: () => import("../views/user/productvip.vue"),
      },
      {
        path: "/helps",
        name: "Help",
        component: () => import("../views/helps/index.vue"),
      },
      // 专家列表
      {
        path: "/professor",
        name: "Professor",
        component: () => import("../views/professor/list.vue"),
      },
      // 专家访谈列表
      {
        path: "/peopledetail",
        name: "Peopledetail",
        component: () => import("../views/professor/peopledetail.vue"),
      },
      // 专家访谈列表详情
      {
        path: "/peoplenewsdetail",
        name: "Peoplenewsdetail",
        component: () => import("../views/professor/peoplenewsdetail.vue"),
      },
      // 视频列表
      {
        path: "/videos",
        name: "Videos",
        component: () => import("../views/videos/list.vue"),
      },
      // 产品与服务
      {
        path: "/product",
        name: "Product",
        component: () => import("../views/product/index.vue"),
      },
      // 产品与服务详情
      {
        path: "/product/productdetail",
        name: "ProductDetail",
        component: () => import("../views/product/ProductDetail.vue"),
      },
      // 行业
      {
        path: "/industry",
        name: "Industry",
        component: () => import("../views/industry/index.vue"),
      },
      // 行业详情
      {
        path: "/industry/industrydetail",
        name: "industryDetail",
        component: () => import("../views/industry/industryDetail.vue"),
      },
      // 绿色服务
      {
        path: "/serve",
        name: "Serve",
        component: () => import("../views/serve/index.vue"),
      },
      // 碳交易
      {
        path: "/business",
        name: "Business",
        component: () => import("../views/business/index.vue"),
      },
      // 新闻列表页
      {
        path: "/news",
        name: "News",
        component: () => import("../views/business/news.vue"),
      },
      // 研报合集列表页
      {
        path: "/reports",
        name: "Reports",
        component: () => import("../views/reports/index.vue"),
      },
      // 研报详情页
      {
        path: "/reportList",
        name: "ReportsList",
        component: () => import("../views/reports/reportList.vue"),
      },
      // 研报预览页面
      {
        path: "/reportPreview",
        name: "ReportPreview",
        component: () => import("../views/reports/reportDetails.vue"),
      },
      // 研报下载列表
      {
        path: "/myDownReport",
        name: "MyDownReport",
        component: () => import("../views/reports/myDownLoad.vue"),
      },
      // 绿色金融页
      {
        path: "/finance",
        name: "Finance",
        component: () => import("../views/finance/index.vue"),
      },
      {
        path: "/financeDetail",
        name: "Finance",
        component: () => import("../views/finance/financeDetail.vue"),
      },
      // {
      //   path: "/research",
      //   name: "Research",
      //   component: () => import("../views/research/index.vue"),
      // },
      // 新闻详情页
      {
        path: "/newsdetail",
        name: "newsDetail",
        component: () => import("../views/business/newsDetail.vue"),
      },
      {
        path: "/consult",
        name: "Consult",
        component: () => import("../views/consult/index.vue"),
      },
      {
        path: "/review",
        name: "Review",
        component: () => import("../views/review/index.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  // 查找最近的有title的路由记录（支持嵌套路由）
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta.title);
  if (nearestWithTitle) {
    let title = nearestWithTitle.meta.title;
    // // 如果是函数，传入to对象获取动态值
    // if (typeof title === 'function') {
    //   title = title(to);
    // }
    document.title = title;
  } else {
    document.title = "碳E通-让碳交易变简单"; // 默认值
  }
  next();
});

// const resetrouter = createRouter()

// export function resetRouter() {
//   const newRouter = createRouter()
//   router.matcher = newRouter.matcher // reset router
// }

export default router;
